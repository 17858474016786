import React, { useEffect, useRef } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Footer from "components/Saas/Footer";
import Navbar from "components/Navbars/ITCreativeNav";
import { Link } from "gatsby";

const CSR = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#ff5722'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Corporate Social  <span> Responsibility (CSR)</span></h1>                              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" alt="" />   
         
        
      </div>
    </header>
      <main className="comming-soon-page style-5" >
        <section className="comming-soon style-5">
          <div className="container" style={{  background: '#fff', padding: 40}}>
            <div className="">
            
              <h3>Empowering Communities, Transforming Lives </h3>
              <p>
                At Mutual Trust mfBank, we believe in building more than
                financial stability; we are committed to creating meaningful and
                lasting change in the communities we serve. Our Corporate Social
                Responsibility (CSR) efforts are focused on empowering lives,
                fostering sustainable growth, and supporting communities across
                Nigeria to realize their fullest potential.{" "}
              </p>
            </div>
            <br />
            <div className="">
                    <h3> Our CSR Pillars </h3>
                    <ul>
                      <li>
                        <b> 1. Financial Inclusion & Literacy</b>
                        <br />
                        We work to bridge the gap in financial inclusion by
                        empowering underserved communities with the knowledge
                        and tools they need to make informed financial
                        decisions. Through workshops, training programs, and
                        outreach initiatives, we’re bringing financial literacy
                        to the forefront, ensuring that more Nigerians have
                        access to banking services, savings plans, and
                        responsible lending.
                      </li>
                    </ul>
             </div>
             <br />
            <div className="">
                    
                    <ul>
                      <li>
                        <b>2.	Community Development & Empowerment</b>
                        <br />
                        
Mutual Trust Microfinance Bank partners with local organizations and leaders to support community-driven projects that improve lives. From funding small businesses and cooperative societies to investing in infrastructure, education, and healthcare, we aim to create resilient communities where everyone has a chance to thrive

                      </li>
                    </ul>
             </div>
             <br />
            <div className="">
                    
                    <ul>
                      <li>
                        <b>3.	Youth & Women Empowerment</b>
                        <br />
                        
                        
We believe that empowering youth and women is key to building a brighter future. Our programs provide financial support, training, and mentorship for young entrepreneurs and female business owners, enabling them to start or scale their businesses with confidence. By investing in youth and women, we are investing in the prosperity of generations to come.


                      </li>
                    </ul>
             </div>
             <br />
            <div className="">
                    
                    <ul>
                      <li>
                        <b> 4.	Environmental Sustainability</b>
                        <br />
                        
                       
Our commitment to a better planet drives us to implement sustainable practices in our banking operations and community engagements. From supporting eco-friendly agriculture projects to promoting environmental awareness, we strive to minimize our footprint and advocate for a cleaner, greener Nigeria.


                      </li>
                    </ul>
             </div>

             <div className="i">
             
              <br />
              <h3>Making a Difference Together </h3>
              <p>
              As we continue to grow, our dedication to impactful CSR initiatives remains at the heart of our mission. We are grateful to our clients, partners, and communities for joining us on this journey to create a better tomorrow. Together, we are not only changing lives but also shaping a sustainable future for all.
              </p>
             </div>
             <div className="">
             
              <br />
              <h3>Contact Us to Get Involved </h3>
              <p>
              
Interested in partnering with us or learning more about our CSR initiatives? Contact us to discover how we can work together to create positive change.

              </p>
             </div>
             <Link
              to="/contact"
              className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-20 mb-20"
            >
              <span>{"Contact Us"}</span>
            </Link>
             <br />
             <i>At Mutual Trust Microfinance Bank, we see our role as more than a financial service provider. We are a partner in progress, a force for good, and a catalyst for positive transformation. Join us in our mission to make a difference.</i>
          </div>
        </section>
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
     <title> Our Corporate Social Initiatives</title>
<meta name="description" content=" Explore our commitment to corporate social responsibility. We are dedicated to empowering individuals, supporting communities, and protecting the environment
" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default CSR;
